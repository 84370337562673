import * as Action from '../../constants/reduxActions';
import initialState from '../initialState';

export const set = (value) => ({
    type: Action.OPEN_FILTER,
    value: value
});

export default function reducer (state = initialState, action) {
    switch(action.type) {
        case Action.OPEN_FILTER:
            return {...state, filter: {...state.filter, opened: action.value}};
        default:
            return state;
    }
}