import React from 'react';
import { ArrowBack } from '../icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';

import { Header, Body, FilterBtn, HistoryItem } from '../components';
import dicts from '../constants/translations';

export default function HistoryPage(props) {
    const parsed = queryString.parse(window.location.search);
    const {establishment_id} = parsed;
    
    const history = useHistory();
    const container = React.useRef(null);

    const token = useSelector((state) => state.token.token);
    const language = useSelector((state) => state.language.language);
    const dates = useSelector((state) => state.filter_dates.filter.dates);
    const establishment = useSelector((state) => state.filter_establishments.filter.establishment);

    const [items, setItems] = React.useState([]);
    const [_estId, _setEstId] = React.useState(typeof establishment_id === 'undefined' ? null : establishment_id);
    const [count, setCount] = React.useState(null);

    React.useEffect(() => {
        axios.get('https://api.lakomanda.net/user/orders', {
            params: {
                token: token,
                language: language
            }
        }).then(res => {
            if (res.data && res.data.data) {
                let _count = res.data.data.count;
                setCount(_count);
                let query = {
                    token: token,
                    page_size: _count,
                    language: language
                };
                if (establishment_id) {
                    query['establishment_id'] = establishment_id;
                }
                axios.get('https://api.lakomanda.net/user/orders', {
                    params: query
                }).then(res2 => {
                    if (res2.data && res2.data.data) {
                        setItems(res2.data.data.items);
                    }
                }).catch(console.log)
            }
        }).catch(console.log)
    }, [token, establishment_id, language]);

    React.useEffect(() => {
        if (establishment && items) {
            items.forEach(est => {
                if (est.establishment.name === establishment) {
                    _setEstId(est.establishment.id);
                }
            })
        }
        if (!establishment) {
            _setEstId(null);
        }
    }, [establishment, items]);

    React.useEffect(() => {
        let since;
        if (dates.since === null) {
            since = dates.since
        }else{
            since = new Date(dates.since).toISOString().split('.000').join('');
        }

        let until;
        if (dates.until === null) {
            until = dates.until
        }else{
            until = new Date(dates.until).toISOString().split('.000').join('');
        }

        let query = { establishment_id: _estId, since: since, until: until, token: token, page_size: count, language: language };
        Object.keys(query).forEach(key => {
            if (!query[key]) {
                delete query[key];
            }
        });

        axios.get('https://api.lakomanda.net/user/orders', {
            params: query
        }).then(res => {
            if (res.data && res.data.data) {
                setItems(res.data.data.items);
            }
        })
    }, [_estId, count, token, dates, language]);

    return (
        <div>
            <Header position="relative" color={'white'}>
                <ArrowBack style={{ padding: 5, width: 35, height: 35, backgroundColor: 'white', borderRadius: 10 }} onClick={() => { history.goBack() }} />
                <p>{dicts[language].translation.HISTORY_LABEL}</p>
                <FilterBtn items={items} bodyref={container} />
            </Header>
            <Body style={{ paddingTop: 0 }}>
                <div style={{ width: '100%' }} ref={container} />
                {items || items.length !== 0 ?
                    items.map((item,i) => {
                        let date = new Date(item.order.datetime);
                        return (
                            <HistoryItem 
                                key={'hitem-'+i} 
                                dateTime={date.toISOString().replace(/T/, ' ').replace(/\..+/, '').replaceAll('-', '/')} 
                                cost={item.order.amount} 
                                items={item.order.items}
                                code={item.establishment.code}
                                coin={item.establishment.coin}
                            />
                        );
                    }) : <div>No Items</div>
                }
            </Body>
        </div>
    );
}