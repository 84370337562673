import React from 'react';
import { Close } from '@material-ui/icons';
import { ArrowBack } from '../icons';
import { Button as Btn, Dialog, AppBar, Toolbar, Slide, IconButton, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import dicts from '../constants/translations';
import { Header, Menu, Body, SideMenu, Button, Map } from '../components';
import axios from 'axios';
import queryString from 'query-string';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog(props) {
    const [open, setOpen] = React.useState(false);

    const language = useSelector((state) => state.language.language);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getCenterProps = () => {
        if (props.center.lat && props.center.lng) {
            return props.center;
        } else {
            return { lat: 43.4289132, lng: 39.9162912 };
        }
    }

    return (
        <div>
            <Btn variant="outlined" color="primary" style={{ padding: 5 }} onClick={handleClickOpen}>
                {dicts[language].translation.BEEN_SET_LOCATION_LABEL}
            </Btn>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6">
                            {dicts[language].translation.BEEN_SET_LOCATION_LABEL}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Map
                    isMarkerShown
                    defaultOptions={{ draggable: false, gestureHandling: 'greedy' }}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDR4qSkoqBiYZh4pS7SQDsIabRXKQCljNo&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    center={getCenterProps()}
                    defaultZoom={17}
                    onChange={() => { }}
                />
            </Dialog>
        </div>
    );
}

export default function ExplorePage(props) {
    const history = useHistory();
    const language = useSelector((state) => state.language.language);
    const token = useSelector((state) => state.token.token);
    const [response, setResponse] = React.useState(null);
    const parsed = queryString.parse(window.location.search);
    const { from } = parsed;
    const { id } = useParams();

    React.useEffect(() => {
        axios.get('https://api.lakomanda.net/establishments/' + id, {
            params: {
                token: token,
                language: language
            }
        }).then(res => {
            if (res.data && res.data.data) {
                setResponse(res.data.data);
            }
        }).catch(console.log);
    }, [token, id, language]);

    return (
        <div>
            <Header position="relative" imageUrl={response !== null ? response.logo : ''}>
                <ArrowBack style={{ padding: 5, width: 35, height: 35, backgroundColor: 'white', borderRadius: 10}} onClick={() => { history.goBack() }} />
                <div />
                <Menu>
                    <SideMenu />
                </Menu>
            </Header>
            { response ? <Body style={{ paddingTop: 0, paddingLeft: 10 }}>
                <h3>{response !== null ? response.name : ''}</h3>
                <p>{response !== null ? response.type.name : ''}</p>
                <p>{response !== null ? response.town : ''}</p>
                <p>{response !== null ? response.province : ''}</p>
                <div style={{
                    paddingBottom: 10
                }}>
                    <FullScreenDialog center={{ lat: response !== null ? response.lat : 0, lng: response !== null ? response.lng : 0 }} />
                </div>
                {
                    response !== null && response.opening_hours ?
                        Object.keys(response.opening_hours).map(key => {
                            return (
                                <div key={key} style={{ color: '#353A57' }}>
                                    {key}
                                    <div key={'time-' + key} style={{ paddingLeft: 10 }}>
                                        {
                                            response.opening_hours[key].map((time, i) => {
                                                return (
                                                    <div key={i}>
                                                        <p>Opens: {time.opening_time}</p>
                                                        <p>Closes: {time.closing_time}</p>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                        : <p>No days</p>
                }
                <Button onClick={() => {
                    if (response !== null) {
                        if (from === 'fav') {
                            axios.delete('https://api.lakomanda.net/user/establishments/'+ response.id,{
                                params: {
                                    token: token,
                                }
                            }).then(res => {
                                history.push('/favourite')
                            }).catch(console.log)
                        } else if (from === 'search') {
                            axios.put('https://api.lakomanda.net/user/establishments', {
                                establishment_id: response.id
                            }, {
                                params: {
                                    token: token,
                                }
                            }).then(res => {
                                history.push('/favourite');
                            }).catch(console.log)
                        }
                    }
                }} btnvariant={''}>{ from === 'search' ? dicts[language].translation.ADD_TO_FAV_LABEL : from === 'fav' ? dicts[language].translation.REMOVE_FROM_FAV_LABEL : ''}</Button>
                <Button onClick={()=>{
                    window.location.href = response.code;
                }} btnvariant={'green'}>{dicts[language].translation.MAKE_AN_ORDER_LABEL}</Button>
                <Button onClick={()=>{
                    history.push('/history/?establishment_id='+response.id);
                }} btnvariant={''}>{dicts[language].translation.SEE_PREV_ORDERS_LABEL}</Button>
            </Body> : <div>Empty response</div>}
        </div>
    );
}