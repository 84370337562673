import React from 'react';
import { Button } from '../components'
import { Grid, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import dicts from '../constants/translations';

export default function FilterItem(props) {
    const language = useSelector((state) => state.language.language);
    return (
        <div style={{ marginTop: 10, marginBottom: 20 }}>
            <Divider />
            <div style={{ paddingLeft: 20, marginRight: '20' }}>
                <Grid container>
                    <Grid item xs={9}>
                        <p style={{ color: 'black', fontWeight: 300 }}>{props.dateTime}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <p style={{ color: 'black', fontWeight: 300, fontSize: 30, margin: 5 }}>{props.cost +' '+ props.coin}</p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        {props.items.map((item, i) => <div key={i}>
                            <p>{item.units} - {item.name} {item.observations && `(${item.observations})`}</p>
                            {
                                item.options.map((option, oi) => {
                                    return (
                                        <p key={'Opt-' + oi} style={{ paddingLeft: 20 }}>{option.name}: {option.option}</p>
                                    );
                                })
                            }
                        </div>)}
                    </Grid>
                </Grid>
            </div>
            {/* <Button onClick={()=>{
                window.location.href = props.code;
            }} btnvariant={'green'}>{dicts[language].translation.REPEAT_ORDER_LABEL}</Button> */}
        </div>
    );
}