import React from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    withScriptjs
} from "react-google-maps";

class MapWithMarker extends React.Component {
    constructor(props) {
        super(props);
        this.ref = null;
        this.state = {
            center: this.props.center,
            zoom: this.props.defaultZoom
        };
    }

    callOnChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    handleCenterChange() {
        this.setState(prevState => ({
            ...prevState, center: { lat: this.ref.getCenter().lat(), lng: this.ref.getCenter().lng() }
        }));
        this.callOnChange();
    }

    handleZoomChange() {
        this.setState(prevState => ({
            ...prevState, zoom: this.ref.getZoom()
        }));
        this.callOnChange();
    }

    render() {
        return (
            <GoogleMap
                defaultOptions={this.props.defaultOptions}
                ref={(mapRef) => { this.ref = mapRef }}
                defaultZoom={this.state.zoom}
                center={this.state.center}
                defaultCenter={this.props.defaultCenter}
                onCenterChanged={this.handleCenterChange.bind(this)}
                onZoomChanged={this.handleZoomChange.bind(this)}
                onClick={this.callOnChange.bind(this)}
            >
                {this.props.isMarkerShown && <Marker position={this.state.center} />}
            </GoogleMap>
        );
    }
}

export default withScriptjs(withGoogleMap(MapWithMarker));
