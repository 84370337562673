import React from 'react';
import { Portal, IconButton, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { Filter } from '../icons';
import { useSelector, useDispatch } from 'react-redux';
import { set as setOpened } from '../redux/reducers/filter_open';
import dicts from '../constants/translations';
import { FilterScroll } from './';

export default function FilterBtn(props) {
    const dispatch = useDispatch();
    const opened = useSelector((state) => state.filter_open.filter.opened);
    const [expanded, setExpanded] = React.useState(null);
    const language = useSelector((state) => state.language.language);

    const handleShowFilter = () => {
        dispatch(setOpened(!opened));
    };

    const handleOpenTab = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <IconButton edge='start' color='inherit' onClick={handleShowFilter} style={{ backgroundColor: opened ? '#293E98' : 'inherit' }}>
                <Filter style={{ backgroundColor: opened ? 'white' : 'inherit', width: 35, height: 35, borderRadius: 10}} />
            </IconButton>
            {opened ? (
                <Portal container={props.bodyref.current}>
                    <Accordion square expanded={expanded === 'panel1'} onChange={handleOpenTab('panel1')}>
                        <AccordionSummary>
                            <Typography>{dicts[language].translation.DATE_LABEL}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ maxHeight: '40vh', backgroundColor: '#D8D8D8', borderRadius: 10 }}>
                            <FilterScroll items={props.items} filtertype='date' />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel2'} onChange={handleOpenTab('panel2')}>
                        <AccordionSummary>
                            <Typography>{dicts[language].translation.ESTABLISHMENT_LABEL}</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ maxHeight: '40vh', backgroundColor: '#D8D8D8', borderRadius: 10 }}>
                            <FilterScroll items={props.items} filtertype='establishment' />
                        </AccordionDetails>
                    </Accordion>
                </Portal>
            ) : null}
        </div>
    );
};