import * as Action from '../../constants/reduxActions';
import initialState from '../initialState';

export const set = (value) => ({
    type: Action.SET_FILTER_DATES,
    value: value
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Action.SET_FILTER_DATES:
            return { ...state, filter: { ...state.filter, dates: action.value } };
        default:
            return state;
    }
}