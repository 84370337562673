import React from 'react';
import { AppBar, Grid, Toolbar } from '@material-ui/core';

export default function Header(props) {
    const styleSwitcher = () => {
        switch (props.color) {
            case 'blue':
                return {
                    backgroundColor: '#293E98',
                    color: '#FFFFFF'
                };

            case 'white':
                return {
                    backgroundColor: '#FFFFFF',
                    color: '#293E98'
                };
            default:
                return {
                    backgroundColor: 'none',
                    color: '#FFFFFF',
                    backgroundImage: 'url('+ props.imageUrl +')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '200px'
                };
        }
    };

    return (
        <AppBar color="inherit" position={props.position ?? 'fixed'} style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            boxShadow: 'none',
            ...styleSwitcher()
        }}>
            <Toolbar style={{
                paddingLeft: 10,
                paddingRight: 0
            }}>
                <Grid container>
                    <Grid item xs={2} style={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        maxHeight: 50
                    }}>
                        { props.children[0] }
                    </Grid>
                    <Grid item xs={8} style={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        { props.children[1] }
                    </Grid>
                    <Grid item xs={2} style={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        maxHeight: 50
                    }}>
                        { props.children[2] }
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};