const initialState = {
    language: 'en-EN',
    token: null,
    filter:{
        dates: {
            since: null,
            until: null
        },
        establishment: null,
        opened: false
    }
};

export default initialState;