import React, { } from 'react';
import { useHistory } from 'react-router';
import { List, ListItem, Divider } from '@material-ui/core';
import { ArrowBack } from '../icons';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { Menu, Body, Header, SideMenu } from '../components';

import { ReactComponent as LogoBlue } from '../icons/logo-blue.svg';

export default function SearchPage(props) {
    const history = useHistory();
    const token = useSelector((state) => state.token.token);
    const language = useSelector((state) => state.language.language);
    const [response, setResponse] = React.useState(null);
    const [categories, setCategories] = React.useState([]);
    const [maxPageSize, setMaxPageSize] = React.useState(0);

    React.useEffect(() => {
        axios.get('https://api.lakomanda.net/establishments/', {
            params: {
                token: token,
                language: language
            }
        }).then(res => {
            if (res.data.data) {
                axios.get('https://api.lakomanda.net/establishments/', {
                    params: {
                        token: token,
                        page_size: res.data.data.count,
                        establishment_page_size: 5,
                        language: language
                    }
                }).then(res => {
                    setResponse(res.data);
                    setMaxPageSize(5);
                }).catch(console.log);
            }
        }).catch(console.log);
    }, [token, language]);

    React.useEffect(() => {
        if (response && response.data && response.data.items) {
            setCategories(response.data.items);
        }
    }, [response])

    return (
        <div>
            <Header color={'white'}>
                <ArrowBack style={{ padding: 5, width: 35, height: 35, backgroundColor: 'white', borderRadius: 10 }} onClick={() => { history.goBack() }} />
                <LogoBlue style={{ maxHeight: 50 }} />
                <Menu>
                    <SideMenu />
                </Menu>
            </Header>
            <Body>
                {categories.length !== 0 ?
                    <List subheader={<li />} style={{
                        width: '100%',
                    }}>
                        {categories.map((category, i) => (
                            <li key={`section-${i}`} style={{
                                backgroundColor: 'inherit'
                            }}>
                                <ul style={{
                                    paddingLeft: 0,
                                }}>
                                    <p style={{ paddingLeft: 10, margin: 4 }}>{category.name}</p>
                                    <ItemsScroller history={history} language={language} setmaxpagesize = {setMaxPageSize.bind(this)} maxpagesize = {maxPageSize} items={category.establishments.items} token={token} response={response} setresponse={setResponse.bind(this)}/>
                                    <Divider />
                                </ul>
                            </li>
                        ))}
                    </List>
                    : <div>No Categories</div>
                }
            </Body>
        </div>
    );
};

class ItemsScroller extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    _onScroll(e) {
        let {token, response, setresponse, setmaxpagesize, maxpagesize} = this.props;
        if ((e.target.scrollWidth - e.target.clientWidth) === e.target.scrollLeft) {
            axios.get('https://api.lakomanda.net/establishments/', {
                params: {
                    token: token,
                    page_size: response.data.count,
                    establishment_page_size: maxpagesize * 2,
                    language: this.props.language
                }
            }).then(res => {
                setmaxpagesize(maxpagesize * 2);
                setresponse(res.data);
            }).catch(console.log);
        }
    }

    render() {
        const { history, items } = this.props;
        return (
            <div>{items && items.length !== 0 ?
                <List onScroll={this._onScroll.bind(this)} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'scroll',
                    padding: 0
                }}>
                    {items.map((item, i) => (
                        <ListItem onClick={() => { history.push('/establishment/' + item.id + '/?from=search') }} style={{
                            minWidth: 100,
                            minHeight: 100,
                            maxWidth: 100,
                            maxHeight: 100,
                            borderRadius: 10,
                            backgroundImage: 'url(' + item.logo + ')',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            margin: 10,
                            padding: 0
                        }} key={`item-${i}`}>
                        </ListItem>
                    ))}
                </List>
                : 'No items'}</div>
        );
    }
}

/*
<List subheader={<li />} style={{
                    width: '100%',
                }}>
                    {['Restaurants', 'Cafe', 'Shop', 'Fastfood', 'Aids', 'Some other'].map((sectionId) => (
                        <li key={`section-${sectionId}`} style={{
                            backgroundColor: 'inherit'
                        }}>
                            <ul style={{
                                paddingLeft: 0,
                            }}>
                                <p style={{ paddingLeft: 10, margin: 4 }}>{sectionId}</p>
                                <List onScroll={ (e)=>{console.log( Math.floor(e.target.scrollLeft / 132) / 6 )} } style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflow: 'scroll',
                                    padding: 0
                                }}>
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item) => (
                                        <ListItem key={`item-${sectionId}-${item}`}>
                                            <Box onClick={()=>{history.push('/establishment/'+item)}} style={{
                                                width: 100,
                                                height: 100,
                                                borderRadius: 10,
                                                backgroundImage: 'url(https://www.bitrefill.com/content/cn/b_rgb%3A2a7fc4%2Cc_pad%2Ch_720%2Cw_1280/v1584444293/opencor.png)',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover'
                                            }}>
                                            </Box>
                                        </ListItem>
                                    ))}
                                </List>
                                <Divider />
                            </ul>
                        </li>
                    ))}
                </List>
*/