import React from 'react';
import { Container, Grid, Button, Typography } from '@material-ui/core';

export default function CustomButton(props) {

    return (
        <Container style={{ width: '100%', paddingTop: 20 }}>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={10}>
                    <Button onClick={props.onClick} variant="contained" style={{ width: '100%', borderRadius: 10, height: 50, backgroundColor: props.btnvariant === 'green' ? '#36DDAD' : '#F2F3F3'}}>
                        <Typography style={{color: props.btnvariant === 'green' ? 'white' : '#353A57'}}>
                            {props.children}
                        </Typography>
                    </Button>
                </Grid>
                <Grid item xs={1}>
                </Grid>
            </Grid>
        </Container>
    );
};