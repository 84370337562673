import React from "react";

class Label extends React.Component {
    constructor(props) {
        super(props);
        this.state = { active: this.props.active };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        this.setState((prevState) => ({
            active: !prevState.active
        }));
        this.props.onClick(e);
    }

    render() {
        const { active } = this.state;
        return (
            <div
                onClick={this.handleClick}
                style={{
                    marginTop: 5,
                    marginBottom: 5,
                    height: 40,
                    borderRadius: 10,
                    backgroundColor: active ? "#293E98" : "#F2F3F3",
                    color: active ? "white" : "#293E98",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column"
                }}
            >
                <p>{this.props.children}</p>
            </div>
        );
    }
}

class ScrollItem extends React.Component {
    render() {
        const { active, onClick } = this.props;

        return (
            <div
                onClick={onClick}
                style={{
                    backgroundColor: active ? "#293E98" : "white",
                    color: active ? "white" : "#293E98",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 3,
                    marginRight: 3,
                    marginTop: 3,
                    borderRadius: 10,
                    minHeight: 40
                }}
            >
                <p style={{ margin: 0 }}>{this.props.children}</p>
            </div>
        );
    }
}

class Scroller extends React.Component {
    constructor(props) {
        super(props);
        this.state = { active: [] };
        this.onClick = this.onClick.bind(this);
    }

    createOnItemClick(key) {
        return () => {
            this.setState((prevState) => {
                const indexOfActive = prevState.active.indexOf(key);
                if (indexOfActive === -1) {
                    return {
                        active: prevState.active.concat(key)
                    };
                } else {
                    return {
                        active: prevState.active.filter((el) => el !== key)
                    };
                }
            });
        };
    }

    onClick() {
        this.props.onChange(this.state.active);
    }

    render() {
        return (
            <div
                onClick={this.onClick}
                style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    overflowY: "scroll",
                    width: "100%",
                    minHeight: "40px",
                    maxHeight: "50vh",
                    backgroundColor: "#D8D8D8",
                    borderRadius: 10,
                    paddingBottom: 3
                }}
            >
                {this.props.items.map((item, index) => {
                    return (
                        <ScrollItem
                            key={index}
                            onClick={this.createOnItemClick(index)}
                            active={this.state.active.includes(index)}
                        >
                            {item}
                        </ScrollItem>
                    );
                })}
            </div>
        );
    }
}

class Selector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hiddenScroller: true
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState((prevState) => ({
            hiddenScroller: !prevState.hiddenScroller
        }));
    }

    render() {
        return (
            <div
                style={{
                    margin: 10
                }}
            >
                <p>{this.state.hiddenScroller ? "hidden" : "not hiden"}</p>
                <Label onClick={this.handleClick}>{this.props.label}</Label>
                <Scroller items={this.props.items} onChange={this.props.onChange} />
            </div>
        );
    }
}
export default Selector;
