import { StarOutline as Star } from '@material-ui/icons';
import { Profile, History, Exit, ArrowForward } from '../icons';
import { Divider, Grid, List, ListItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { useCookies } from 'react-cookie';

import dicts from '../constants/translations';
import { PopMenu } from '../components';
import {set as setToken} from '../redux/reducers/token';

export default function SideMenu(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();
    const history = useHistory();
    const language = useSelector((state) => state.language.language);

    function _exitApp(){
        removeCookie('token');
        dispatch(setToken(null));
        history.push('/');
    }

    return (
        <List>
            <ListItem style={{ width: '80vw', maxHeight: 50, color: '#293E98' }} onClick={() => { history.push('/profile') }}>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item xs={2}>
                        <Profile style={{ width: 35, height: 35 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <h4 style={{ margin: 0, paddingLeft: 10, paddingBottom: 5 }}>{dicts[language].translation.PROFILE_LABEL}</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <ArrowForward style={{ width: 35, height: 35 }} />
                    </Grid>
                </Grid>
            </ListItem>

            <ListItem style={{ width: '80vw', maxHeight: 50, color: '#293E98' }} onClick={() => { history.push('/history') }}>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item xs={2}>
                        <History style={{ width: 35, height: 35 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <h4 style={{ margin: 0, paddingLeft: 10, paddingBottom: 5 }}>{dicts[language].translation.HISTORY_LABEL}</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <ArrowForward style={{ width: 35, height: 35 }} />
                    </Grid>
                </Grid>
            </ListItem>

            <ListItem style={{ width: '80vw', maxHeight: 50, color: '#293E98' }} onClick={() => { history.push('/favourite') }}>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item xs={2}>
                        <Star style={{ fontSize: 40 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <h4 style={{ margin: 0, paddingLeft: 10, paddingBottom: 5 }}>{dicts[language].translation.FAV_LABEL}</h4>
                    </Grid>
                    <Grid item xs={2}>
                        <ArrowForward style={{ width: 35, height: 35 }} />
                    </Grid>
                </Grid>
            </ListItem>

            <ListItem style={{ width: '80vw', maxHeight: 50, color: '#293E98' }} onClick={_exitApp.bind(this)}>
                <Grid container style={{ alignItems: 'center' }}>
                    <Grid item xs={2}>
                        <Exit style={{ width: 35, height: 35 }} />
                    </Grid>
                    <Grid item xs={8}>
                        <h4 style={{ margin: 0, paddingLeft: 10, paddingBottom: 5 }}>{dicts[language].translation.EXIT_LABEL}</h4>
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>
            </ListItem>

            <Divider />

            <PopMenu menu />
        </List>
    );
};