import { Body } from '../components';
import React from 'react';
import ClickNHold from 'react-click-n-hold';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ArrowBack,
  ArrowForward,
  Email,
  Exit,
  Filter,
  History,
  Home,
  LangCatalan,
  LangEnglish,
  LangGerman,
  LangRussian,
  LangSpanish,
  Language,
  LocationFilled,
  LocationOutline,
  LogoBlue,
  LogoWhite,
  Menu,
  Phone,
  Profile } from '../icons';

class Shaker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            className: ''
        };
    }
    start(e) {
        console.log('START');
    }

    end(e, enough) {
        console.log('END');
        console.log(enough ? 'Click released after enough time' : 'Click released too soon');
    }

    clickNHold(e) {
        console.log('CLICK AND HOLD');
        this.setState(prevState => {
            return (
                {
                    className: 'shake'
                }
            );
        });
    }

    render() {
        return (
            <ClickNHold
                time={2}
                onStart={this.start}
                onClickNHold={this.clickNHold.bind(this)}
                onEnd={this.end} >
                <div className={this.state.className}>Click and hold</div>
            </ClickNHold>
        );
    }
}

const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ExamplePage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        items: getItems(10)
      };
      this.onDragEnd = this.onDragEnd.bind(this);
    }
  
    onDragEnd(result) {
      if (!result.destination) {
        return;
      }
  
      const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
      );
  
      this.setState({
        items
      });
    }

    render() {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  }

  function TestIcons(props){
    return <table style={{backgroundColor: 'black', color: 'white'}}>
      <tbody>
        <tr><td>ArrowBack</td><td><ArrowBack style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>ArrowForward</td><td><ArrowForward style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>Email</td><td><Email style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>Exit</td><td><Exit style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>Filter</td><td><Filter style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>History</td><td><History style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>Home</td><td><Home style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LangCatalan</td><td><LangCatalan style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LangEnglish</td><td><LangEnglish style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LangGerman</td><td><LangGerman style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LangRussian</td><td><LangRussian style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LangSpanish</td><td><LangSpanish style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>Language</td><td><Language style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LocationFilled</td><td><LocationFilled style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LocationOutline</td><td><LocationOutline style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LogoBlue</td><td><LogoBlue style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>LogoWhite</td><td><LogoWhite style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>Menu</td><td><Menu style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>Phone</td><td><Phone style={{width: '100px', height: '100px'}} /></td></tr>
        <tr><td>Profile</td><td><Profile style={{width: '100px', height: '100px'}} /></td></tr>
      </tbody>
    </table>;
  }

export default TestIcons;