import PageDot from './pageDot';
import React from 'react';

function DotScroller(props) {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'scroll',
            scrollbarWidth: 'none',
            maxWidth: '70vw'
        }}>
            {props.arr.map((v) => {
                return (
                    <PageDot
                        key={v}
                        onClick={()=>{props.setPage(v)}}
                        activeDot={props.curr === v ? props.activeDot : null}
                        active={props.curr === v ? true : false}
                    />);
            })}
        </div>
    );
}

export default function DotStrip(props) {

    let arr = [];
    for (let i = 1; i <= props.max; i++) {
        arr.push(i);
    }

    return (
        <div style={{
            position: 'fixed',
            top: '70px',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
        }}>
            <DotScroller arr={arr} curr={props.curr} activeDot={props.activeDot} setPage={props.setPage}/>
        </div>
    );
}