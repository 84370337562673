import React from 'react';
import { Menu, ListItem, Grid, MenuItem, Container, TextField } from '@material-ui/core';
import { Language } from '../icons';
import { useSelector, useDispatch } from 'react-redux';

import dicts from '../constants/translations';
import { set as setLanguage } from '../redux/reducers/language';

export default function PopMenu(props) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const chooseLayout = (menu) => {
    if (menu) {
      return (
        <ListItem style={{ width: '80vw', maxHeight: 50, color: '#293E98' }} onClick={handleClick}>
          <Grid container style={{ alignItems: 'center' }}>
            <Grid item xs={2}>
              <Language style={{ fontSize: 40 }} />
            </Grid>
            <Grid item xs={8}>
              <h4 style={{ margin: 0, paddingLeft: 10, paddingBottom: 5 }}>{dicts[language].language}</h4>
            </Grid>
            <Grid item xs={2}>
            </Grid>
          </Grid>
        </ListItem>
      );
    } else {
      return (
        <Container style={{ width: '100%' }}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs={1}>
              <Language style={{ color: '#293E98', height: 35, width: 35 }} />
            </Grid>
            <Grid item xs={11}>
              <TextField 
                required={props.required}
                onClick={handleClick} 
                label={dicts[language].translation.LANGUAGE_LABEL} 
                value={dicts[language].language} 
                style={{ width: '90%', marginLeft: 20 }} 
              />
            </Grid>
          </Grid>
        </Container>
      );
    }
  };


  return (
    <div>

      {chooseLayout(props.menu)}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          Object.keys(dicts).map((key, i)=>{
            return (
              <MenuItem 
                key={i}
                style={{ minWidth: '50vw' }}
                onClick={() => { 
                  handleClose();
                  dispatch(setLanguage(key));
                  if (props.onChange) props.onChange(key); 
                }}>
                {dicts[key].language}
              </MenuItem>
            );
          })
        }
      </Menu>
    </div>
  );
}


/*
import React from 'react';
import { Menu } from '@material-ui/core';

export default function PopMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  let i = 0;
  return (
    <div>
        {React.createElement(props.actionBtn.type, {...props.actionBtn.props, onClick: handleClick})}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.children.map(element => {
            ++i;
            return React.createElement(element.type, {...element.props, key:'menu-btn-'+i, onClick: (e) => {handleClose(e); element.props.onClick(e);} });
        })}
      </Menu>
    </div>
  );
}
*/