import React from 'react';
import { Close } from '@material-ui/icons';
import { ArrowBack, Profile, Home, LocationFilled, Phone, Email} from '../icons';
import { Typography, Slide, Button as Btn, Dialog, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios'

import dicts from '../constants/translations';
import { Header, Body, Menu, SideMenu, InputField, Button, Map, PopMenu } from '../components';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog(props) {
    const [open, setOpen] = React.useState(false);

    const language = useSelector((state) => state.language.language);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getCenterProps = () => {
        if (props.center.lat && props.center.lng) {
            return props.center;
        } else {
            return { lat: 43.4289132, lng: 39.9162912 };
        }
    }

    return (
        <div>
            <Btn variant="outlined" color="primary" style={{ padding: 5 }} onClick={handleClickOpen}>
                {props.center.lat && props.center.lng ? dicts[language].translation.BEEN_SET_LOCATION_LABEL : dicts[language].translation.SET_LOCATION_LABEL}
            </Btn>
            <Btn color="default" style={{ color: 'red', padding: 5 }} onClick={props.clearPos}>
                {dicts[language].translation.CLEAR_LABEL}
            </Btn>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6">
                            {props.center.lat && props.center.lng ? dicts[language].translation.BEEN_SET_LOCATION_LABEL : dicts[language].translation.SET_LOCATION_LABEL}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Map
                    isMarkerShown
                    defaultOptions={{ draggable: true, gestureHandling: 'greedy' }}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDR4qSkoqBiYZh4pS7SQDsIabRXKQCljNo&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    center={getCenterProps()}
                    defaultZoom={17}
                    onChange={props.onChange}
                />
            </Dialog>
        </div>
    );
}

export default function ProfilePage(props) {
    const language = useSelector((state) => state.language.language);
    const token = useSelector((state) => state.token.token);
    const [form, setForm] = React.useState({
        username: '',
        address: '',
        street_number: '',
        floor: '',
        postal_code: '',
        lng: '',
        lat: '',
        phone: '',
        town: '',
        province: '',
        email: '',
        language: language
    });
    const history = useHistory();

    React.useEffect(() => {
        axios.get('https://api.lakomanda.net/user', {
            params: {
                token: token,
                language: language
            }
        }).then(res => {
            if (res.data && res.data.data) {
                setForm(res.data.data);
            }
        }).catch(console.log);
    }, [token, language]);

    function formOnChange(obj) {
        setForm({ ...form, ...obj });
    }

    function handleSubmit() {
        let elements = Object.keys(form);
        let formOk = false;
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (form[element]) {
                formOk = true;
            } else {
                formOk = false;
                break;
            }
        }

        if (formOk) {
            axios.post('https://api.lakomanda.net/user', form,{
                params:{
                    token: token
                }
            })
            .then(res => {
                console.log(res.data);
            }).catch(console.log);
        }
    }

    return (
        <div>
            <Header position="relative" color={'white'}>
                <ArrowBack style={{ width: 35, height: 35, padding: 5, backgroundColor: 'white', borderRadius: 10 }} onClick={() => { history.goBack() }} />
                <p>{dicts[language].translation.PROFILE_LABEL}</p>
                <Menu>
                    <SideMenu />
                </Menu>
            </Header>
            <Body style={{ paddingTop: 10, maxHeigth: 'none', height: 'none' }}>
                <Typography variant='h6' style={{ color: '#293E98', paddingLeft: 20 }}>{dicts[language].translation.UPDATE_PROFILE_LABEL}</Typography>

                <InputField value={form.username} onChange={(e) => { formOnChange({ username: e.target.value }) }} label={dicts[language].translation.NAME_LABEL} icon={<Profile style={{width: 35, height: 35}} />} />

                <InputField value={form.address} onChange={(e) => { formOnChange({ address: e.target.value }) }} label={dicts[language].translation.STREET_LABEL} icon={<Home style={{width: 35, height: 35}} />} />

                <InputField value={form.street_number} onChange={(e) => { formOnChange({ street_number: e.target.value }) }} label={dicts[language].translation.ENTRANCE_LABEL} icon={<div />} />

                <InputField value={form.floor} onChange={(e) => { formOnChange({ floor: e.target.value }) }} label={dicts[language].translation.FLOOR_LABEL} icon={<div />} />

                <InputField value={form.town} onChange={(e) => { formOnChange({ town: e.target.value }) }} label={dicts[language].translation.TOWN} icon={<div />} />

                <InputField value={form.province} onChange={(e) => { formOnChange({ province: e.target.value }) }} label={dicts[language].translation.PROVINCE} icon={<div />} />

                <InputField value={form.postal_code} onChange={(e) => { formOnChange({ postal_code: e.target.value }) }} label={dicts[language].translation.POST_CODE_LABEL} icon={<div />} />

                <InputField component={<FullScreenDialog center={{ lat: form.lat, lng: form.lng }} clearPos={() => { formOnChange({ lat: '', lng: '' }) }} onChange={(e) => { formOnChange({ lat: e.center.lat, lng: e.center.lng }) }} />} icon={<LocationFilled style={{width: 35, height: 35}}/>} />

                <InputField value={form.phone} onChange={(e) => { formOnChange({ phone: e.target.value }) }} label={dicts[language].translation.PHONE_LABEL} icon={<Phone style={{width: 35, height: 35}}/>} />

                <InputField value={form.email} onChange={(e) => { formOnChange({ email: e.target.value }) }} label={dicts[language].translation.EMAIL_LABEL} icon={<Email style={{width: 35, height: 35}} />} />

                <PopMenu value={form.language} onChange={(e) => { formOnChange({ language: e }) }} />

                <Button onClick={() => { handleSubmit(); }} btnvariant='green'>{dicts[language].translation.UPDATE_PROFILE_LABEL}</Button>

            </Body>
        </div>
    );
}