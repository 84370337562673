import { combineReducers, createStore } from 'redux';

import languageReducer from './reducers/language';
import tokenReducer from './reducers/token';
import filter_datesReducer from './reducers/filter_dates';
import filter_establishmentsReducer from './reducers/filter_establishments';
import filter_openReducer from './reducers/filter_open';

const reducer = combineReducers({
    language: languageReducer,
    token: tokenReducer,
    filter_dates: filter_datesReducer,
    filter_establishments: filter_establishmentsReducer,
    filter_open: filter_openReducer
});

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

//TODO: Remove
// store.subscribe(() => console.log(store.getState()));

export default store;