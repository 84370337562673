import React from 'react';
import { Container, Grid, FormControlLabel, Checkbox } from '@material-ui/core';

export default function CustomCheckBox(props) {
    const [state, setState] = React.useState(false);
    return (
        <Container style={{ width: '90%', paddingTop: 20, marginLeft: 10 }}>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox required={props.required}
                            onChange={props.onChange}
                            onClick={() => { setState(!state) }}
                            checked={state}
                            color="primary" 
                        />}
                        label={<p>{props.label}</p>}
                        labelPlacement="end"
                    />
                </Grid>
            </Grid>
        </Container>
    );
};