import * as Action from '../../constants/reduxActions';
import initialState from '../initialState';

export const set = (value) => ({
    type: Action.SET_LANGUAGE,
    value: value
});

export default function reducer (state = initialState, action) {
    switch(action.type) {
        case Action.SET_LANGUAGE:
            return {...state, language: action.value};
        default:
            return state;
    }
}