//Core imports
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

//App import
import App from './App';

//Redux import
import store from './redux/configureStore';

//Service imports
import reportWebVitals from './reportWebVitals';

//Styles imports
import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>
  , document.getElementById('root')
);

reportWebVitals();