import React from 'react';
import { Fab as FloatingButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export default function Fab(props) {
    let history = useHistory();
    return (
        <FloatingButton style={{
            position: 'fixed',
            bottom: '25px',
            right: '25px',
            zIndex: 2,
            boxShadow: 'none',
            backgroundColor: '#36DDAD',
            color: 'white',
            alignContent: 'center'
        }} onClick={() => { history.push('/search'); }}>
            <Add />
        </FloatingButton>
    );
};