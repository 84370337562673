import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { set as setFilterDates } from '../redux/reducers/filter_dates';
import { set as setFilterEstablishments } from '../redux/reducers/filter_establishments';
import { set as filterOpen } from '../redux/reducers/filter_open';

export default function FilterItem(props) {
    const [active, setActive] = React.useState(typeof props.active === 'undefined' ? false : props.active);
    const dispatch = useDispatch();
    const dates = useSelector((state) => state.filter_dates.filter.dates);
    const establishment = useSelector((state) => state.filter_establishments.filter.establishment);
    const opened = useSelector((state) => state.filter_open.filter.opened);

    function handleChanges(){
        if (active) {
            if (props.filtertype === 'date') {
                dispatch(setFilterDates({since: props.value.since, until: props.value.until}));
            } else {
                dispatch(setFilterEstablishments(props.value));
            }
        } else {
            if (props.filtertype === 'date') {
                dispatch(setFilterDates({since: null, until: null}));
            } else {
                dispatch(setFilterEstablishments(null));
            }
        }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(handleChanges.bind(this), [active])

    const handleClick = () => {
        setActive(!active);
        setTimeout(() => {
            dispatch(filterOpen(false));
        }, 0);
    };

    return (
        <div onClick={handleClick.bind(this)}
            style={{
                margin: 3, backgroundColor: active ? '#293E98' : 'white', color: active ? 'white' : '#293E98', borderRadius: 10
            }}>
            {props.children}
        </div>
    );
}

/* import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { set as setFilterDates } from '../redux/reducers/filter_dates';
import { set as setFilterEstablishments } from '../redux/reducers/filter_establishments';

export default function FilterItem(props) {
    const [active, setActive] = React.useState(typeof props.active === 'undefined' ? false : props.active);
    const dispatch = useDispatch();
    const dates = useSelector((state) => state.filter_dates.filter.dates);
    const establishments = useSelector((state) => state.filter_establishments.filter.establishments);

    useEffect(() => {
        const bufSet = new Set();
        if (props.filtertype === 'date') {
            dates.forEach(v => bufSet.add(v));
        } else {
            establishments.forEach(v => bufSet.add(v));
        }

        if (active) {
            bufSet.add(props.value);
        } else {
            bufSet.delete(props.value);
        }

        if (props.filtertype === 'date') {
            dispatch(setFilterDates(Array.from(bufSet)));
        } else {
            dispatch(setFilterEstablishments(Array.from(bufSet)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    const handleClick = () => {
        if (props.filtertype === 'date') {
            if (dates.length < 2) {
                setActive(!active);
            }else{
                setActive(false);
            }
        } else {
            if (establishments.length === 0) {
                setActive(true);
            }else{
                setActive(false);
            }
        }
    };

    return (
        <div onClick={handleClick.bind(this)}
            style={{
                margin: 3, backgroundColor: active ? '#293E98' : 'white', color: active ? 'white' : '#293E98', borderRadius: 10
            }}>
            {props.children}
        </div>
    );
} */