export default function PageDot(props) {
    return (
        <div ref={props.activeDot} onClick={props.onClick} style={{
            backgroundColor: props.active ? '#293E98' : '#D8D8D8',
            minWidth: 10,
            minHeight: 10,
            maxWidth: 30,
            maxHeight: 30,
            borderRadius: 10,
            margin: 4,
            marginLeft: '1vw',
            marginRight: '1vw',
        }}>
        </div>
    );
}