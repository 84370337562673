import React from 'react';
import { Container, Grid, TextField } from '@material-ui/core';

export default function InputField(props) {
    const [value, setValue] = React.useState(props.value);
    React.useEffect(()=>{
        if (props.value) {
            setValue(props.value);
        }
    },[props])
    return (
        <Container style={{ width: '100%' }}>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={1}>
                    {props.icon}
                </Grid>
                <Grid item xs={11}>
                    {props.label && <TextField value={value} required={props.required} onChange={(e)=>{
                        props.onChange(e);
                        setValue(e.target.value);
                    }} label={props.label} style={{ width: '90%', marginLeft: 20 }} />}
                    {props.component && <div style={{ width: '90%', marginLeft: 20, marginTop: 5 }}>{props.component}</div>}
                </Grid>
            </Grid>
        </Container>
    );
};