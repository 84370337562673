import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import { set as setToken } from './redux/reducers/token';
import { set as setLanguage } from './redux/reducers/language';

import { ExamplePage, RegisterPage, FavouritePage, SearchPage, HistoryPage, ExplorePage, ProfilePage } from './pages';

function HandleToken(props) {
  const token = useSelector((state) => state.token.token);
  const [response, setResponse] = React.useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get('https://api.lakomanda.net/token',{
      params:{
        token: token
      }
    })
      .then(res => {
        setResponse(res);
      }).catch(console.log);
  }, [token]);

  function chooseScenario(response) {
    if (response) {
      if (response.data) {
        if (response.data.errors.length === 0) {
          if (response.data.data === true) {
            return <Redirect to={'/register'} />;
          }
          if (!response.data.data.user) {
            return <Redirect to={'/register'} />;
          } else {
            if (response.data.data.user.language) {
              let lang = response.data.data.user.language;
              dispatch(setLanguage(lang));
            }
            return <Redirect to={'/favourite'} />;
          }
        } else {
          return <div>Errors from server:{response.data.errors.map((v, i) => <p key={'err-' + i}>{v.description}</p>)}</div>
        }
      } else {
        return <div>Empty response!</div>;
      }
    }
  }

  if (token === null) {
    return (
      <div>Token is missing</div>
    );
  } else {
    return <div>{chooseScenario(response)}</div>;
  }
}

function App(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const dispatch = useDispatch();
  const parsed = queryString.parse(window.location.search);
  if (parsed.token){
    setCookie('token', parsed.token);
    dispatch(setToken(parsed.token));
  }

  const token = useSelector((state) => state.token.token);
  if (!token) {
    dispatch(setToken(cookies.token));
  }

  React.useEffect(()=>{
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  },[])

  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <HandleToken />
        </Route>
        <Route exact path='/register'>
          <RegisterPage />
        </Route>
        <Route exact path='/favourite'>
          <FavouritePage />
        </Route>
        <Route exact path='/search'>
          <SearchPage />
        </Route>
        <Route exact path='/example'>
          <ExamplePage />
        </Route>
        <Route exact path='/history'>
          <HistoryPage />
        </Route>
        <Route exact path='/establishment/:id'>
          <ExplorePage />
        </Route>
        <Route exact path='/profile'>
          <ProfilePage />
        </Route>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </Router>
  );

}

export default App;