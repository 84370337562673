import React from 'react';
import { FilterItem } from './';
import { useSelector } from 'react-redux';
import { dateToFormatYearMonth, dateToReduxFormatYearMonth } from '../datesHelper';
import dicts from '../constants/translations';

export default function FilterScroll(props) {
    const language = useSelector((state) => state.language.language);
    const datesRedux = useSelector((state) => state.filter_dates.filter.dates);
    const establishmentRedux = useSelector((state) => state.filter_establishments.filter.establishment);

    let sourceItems = props.items;

    function datesList(items) {
        let dates = [];
        items.forEach(item => {
            if (item.order.datetime) {
                dates.push(item.order.datetime);
            }
        });
        let setDates = new Set();
        dates.forEach((v, i) => {
            let date = new Date(v);
            let strdate = date.toISOString().replace(/T/, ' ').replace(/\..+/, '').replaceAll('-', '/').split(' ')[0];
            setDates.add(strdate.slice(0, 7));
        });
        let clearedDates = Array.from(setDates);
        let intervals = [];
        clearedDates.forEach((v, i) => {
            let bufSince = new Date(v+'/01')
            let since = new Date(bufSince.getFullYear(), bufSince.getMonth(), 1, 0, 0, 0);

            let bufUntil = new Date(v+'/01')
            let until = new Date(bufUntil.getFullYear(), bufUntil.getMonth() + 1, 1);
            until.setHours(23)
            until.setMinutes(59)
            until.setSeconds(59)
            intervals.push({ since: since, until: until });
        });

        return intervals.map((v, i) => {
            let active = false
            if (dateToReduxFormatYearMonth(v) === dateToReduxFormatYearMonth(datesRedux)) {
                active = true;
            }
            return (
                <FilterItem active={active} filtertype={'date'} value={v} key={'filter-date-' + i}>
                    <p style={{ paddingLeft: 10, textAlign: 'center' }}>{dateToFormatYearMonth(v, dicts[language].translation.MONTH_NAMES)}</p>
                </FilterItem>
            );
        });
    }

    function establishmentsList(items) {
        let est = [];
        items.forEach(item => {
            let has = false;
            est.forEach((val, index) => {
                if (val === item.establishment.name) {
                    has = true;
                }
            });
            if (!has) {
                est.push(item.establishment.name);
            }
        });
        return est.map((v, i) => {
            let active = false
            if (v === establishmentRedux) {
                active = true;
            }
            return (
                <FilterItem active={active} filtertype={'establishments'} value={v} key={'filter-est-' + i}>
                    <p style={{ paddingLeft: 10, textAlign: 'center' }}>{v}</p>
                </FilterItem>
            );
        });
    }

    function typeSelector(type) {
        if (type === 'date') {
            return datesList(sourceItems);
        } else {
            return establishmentsList(sourceItems);
        }
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            width: '100%'
        }}>
            {props.items.lenght !== 0 ? typeSelector(props.filtertype) : <div>No items</div>}
        </div>
    );
};

/**
 {
                props.items.length !== 0 ?
                    (()=>{
                        if (props.filtertype === 'date') {
                            let dates = [];
                            props.items.forEach(item=>{
                                dates.push(item.order.datetime);
                            });
                            return dates.map((v,i)=>{
                                let date = new Date(v);
                                let active = false
                                datesRedux.forEach((value,i)=>{
                                    if (v === value) {
                                        active = true;
                                    }
                                })
                                return(
                                    <FilterItem active={active} filtertype={props.filtertype} value={`${v}`} key={'filter-date-'+i}>
                                        <p style={{ paddingLeft: 10, textAlign: 'center' }}>{date.toISOString().replace(/T/, ' ').replace(/\..+/, '').replaceAll('-', '/')}</p>
                                    </FilterItem>
                                );
                            });
                        }else{
                            let est = [];
                            props.items.forEach(item=>{
                                est.push(item.establishment.name);
                            });
                            return est.map((v,i)=>{
                                let active = false
                                establishmentsRedux.forEach((value,i)=>{
                                    if (v === value) {
                                        active = true;
                                    }
                                })
                                return(
                                    <FilterItem active={active} filtertype={props.filtertype} value={v} key={'filter-est-'+i}>
                                        <p style={{ paddingLeft: 10, textAlign: 'center' }}>{v}</p>
                                    </FilterItem>
                                );
                            })
                        }
                    })()
                : <div>No items</div>
            }
 */