const ru_lang = {
    REGISTER_HEADER: 'Добро пожаловать на QUBE Cloud!',
    REGISTER_LABEL: 'Регистрация',
    YOUR_PROFILE: 'Ваш профиль',
    NAME_LABEL: 'Имя',
    STREET_LABEL: 'Улица',
    ENTRANCE_LABEL: 'Апартамент',
    FLOOR_LABEL: 'Этаж',
    POST_CODE_LABEL: 'Почтовый индекс',
    SET_LOCATION_LABEL: 'Установить позицию',
    BEEN_SET_LOCATION_LABEL: 'Позиция установлена',
    CLEAR_LABEL: 'Очистить',
    PHONE_LABEL: 'Телефон',
    EMAIL_LABEL: 'Эл. почта',
    LANGUAGE_LABEL: 'Язык',
    AGREE_POLICY_LABEL: 'Я согласен с политикой конфиденциальности',
    POLICY_LABEL: 'Политика конфиденциальности',
    CURRENT_LOCATION_LABEL: 'Текущая позиция',
    PROFILE_LABEL: 'Ваш профиль',
    HISTORY_LABEL: 'История заказов',
    EXIT_LABEL: 'Выйти',
    CHOOSE_LANGUAGE_LABEL: 'Выбор языка',
    WATCH_LOCATION_LABEL: 'Посмотреть гео позицию',
    ADD_TO_FAV_LABEL: 'Добавить в Избранное',
    REMOVE_FROM_FAV_LABEL: 'Удалить из Избранного',
    FAV_LABEL: 'Избранное',
    MAKE_AN_ORDER_LABEL: 'Сделать заказ',
    SEE_PREV_ORDERS_LABEL: 'Посмотреть предыдущие заказы',
    PREV_ORDERS_LABEL: 'Предыдущие заказы',
    DATE_LABEL: 'Дата',
    ESTABLISHMENT_LABEL: 'Заведение',
    REPEAT_ORDER_LABEL: 'Повторить заказ',
    UPDATE_PROFILE_LABEL: 'Обновить профиль',
    NO_RESULTS_LABEL: 'Нет результатов',
    CHANGE_FILTERS_LABEL: 'Измените фильтры',
    TOWN: 'Город',
    PROVINCE: 'Провинция',
    MONTH_NAMES: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    DAY_NAMES: {
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Среда',
        thursday: 'Четверг',
        friday: 'Пятница',
        saturday: 'Суббота',
        sunday: 'Воскресенье'
    }
};

const es_lang = {
    REGISTER_HEADER: '¡Bienvenid@ a QUBE Cloud!',
    REGISTER_LABEL: 'Regístrate',
    YOUR_PROFILE: 'Tu perfil',
    NAME_LABEL: 'Nombre',
    STREET_LABEL: 'Calle',
    ENTRANCE_LABEL: 'Piso',
    FLOOR_LABEL: 'Planta',
    POST_CODE_LABEL: 'Código postal',
    SET_LOCATION_LABEL: 'Escoger ubicación',
    BEEN_SET_LOCATION_LABEL: 'Ubicación escogida',
    CLEAR_LABEL: 'Limpiar',
    PHONE_LABEL: 'Teléfono',
    EMAIL_LABEL: 'E-mail',
    LANGUAGE_LABEL: 'Idioma',
    AGREE_POLICY_LABEL: 'Acepto la política de privacidad',
    POLICY_LABEL: 'La política de privacidad',
    CURRENT_LOCATION_LABEL: 'Ubicación actual',
    PROFILE_LABEL: 'Tu perfil',
    HISTORY_LABEL: 'Historial de pedidos',
    EXIT_LABEL: 'Salir',
    CHOOSE_LANGUAGE_LABEL: 'Elige el idioma',
    WATCH_LOCATION_LABEL: 'Ver la ubicación',
    ADD_TO_FAV_LABEL: 'Agregar a favoritos',
    REMOVE_FROM_FAV_LABEL: 'Quitar de favoritos',
    FAV_LABEL: 'Lista de favoritos',
    MAKE_AN_ORDER_LABEL: 'Hacer un pedido',
    SEE_PREV_ORDERS_LABEL: 'Ver pedidos anteriores',
    PREV_ORDERS_LABEL: 'Pedidos anteriores',
    DATE_LABEL: 'Fecha',
    ESTABLISHMENT_LABEL: 'Establecimiento',
    REPEAT_ORDER_LABEL: 'Repetir el pedido',
    UPDATE_PROFILE_LABEL: 'Editar perfil',
    NO_RESULTS_LABEL: 'No hay resultados',
    CHANGE_FILTERS_LABEL: 'Editar filtros',
    TOWN: 'Ciudad',
    PROVINCE: 'Provincia',
    MONTH_NAMES: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    DAY_NAMES: {
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miercoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sabado',
        sunday: 'Domingo'
    }
};

const en_lang = {
    REGISTER_HEADER: 'Welcome to QUBE Cloud!',
    REGISTER_LABEL: 'Sign up',
    YOUR_PROFILE: 'Your profile',
    NAME_LABEL: 'Name',
    STREET_LABEL: 'Street',
    ENTRANCE_LABEL: 'Apartment number',
    FLOOR_LABEL: 'Floor number',
    POST_CODE_LABEL: 'Postal code',
    SET_LOCATION_LABEL: 'Set location',
    BEEN_SET_LOCATION_LABEL: 'Location set',
    CLEAR_LABEL: 'Clear',
    PHONE_LABEL: 'Phone number',
    EMAIL_LABEL: 'E-mail',
    LANGUAGE_LABEL: 'Language',
    AGREE_POLICY_LABEL: 'I agree with the privacy policy',
    POLICY_LABEL: 'Privacy policy',
    CURRENT_LOCATION_LABEL: 'Current location',
    PROFILE_LABEL: 'Your profile',
    HISTORY_LABEL: 'Order history',
    EXIT_LABEL: 'Log out',
    CHOOSE_LANGUAGE_LABEL: 'Language selection',
    WATCH_LOCATION_LABEL: 'View location',
    ADD_TO_FAV_LABEL: 'Add to favourites',
    REMOVE_FROM_FAV_LABEL: 'Remove from favourites',
    FAV_LABEL: 'Favourites',
    MAKE_AN_ORDER_LABEL: 'Make an order',
    SEE_PREV_ORDERS_LABEL: 'View previous orders',
    PREV_ORDERS_LABEL: 'Previous orders',
    DATE_LABEL: 'Date',
    ESTABLISHMENT_LABEL: 'Establishment',
    REPEAT_ORDER_LABEL: 'Repeat the order',
    UPDATE_PROFILE_LABEL: 'Edit profile',
    NO_RESULTS_LABEL: 'No results',
    CHANGE_FILTERS_LABEL: 'Edit search filters',
    TOWN: 'Town',
    PROVINCE: 'Province',
    MONTH_NAMES: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    DAY_NAMES: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
    }
};

const de_lang = {
    REGISTER_HEADER: 'Willkommen bei QUBE Cloud!',
    REGISTER_LABEL: 'Anmelden',
    YOUR_PROFILE: 'Dein Profil',
    NAME_LABEL: 'Name',
    STREET_LABEL: 'Straße',
    ENTRANCE_LABEL: 'Wohnung',
    FLOOR_LABEL: 'Etage',
    POST_CODE_LABEL: 'Postleitzahl',
    SET_LOCATION_LABEL: 'Ort festlegen',
    BEEN_SET_LOCATION_LABEL: 'Ort festgelegt',
    CLEAR_LABEL: 'Löschen',
    PHONE_LABEL: 'Telefonnummer',
    EMAIL_LABEL: 'Email',
    LANGUAGE_LABEL: 'Sprache',
    AGREE_POLICY_LABEL: 'Ich akzeptiere die Datenschutzrichtlinie',
    POLICY_LABEL: 'Datenschutz-Bestimmungen',
    CURRENT_LOCATION_LABEL: 'Aktueller Standort',
    PROFILE_LABEL: 'Dein Profil',
    HISTORY_LABEL: 'Bestellverlauf',
    EXIT_LABEL: 'Ausloggen',
    CHOOSE_LANGUAGE_LABEL: 'Sprachauswahl',
    WATCH_LOCATION_LABEL: 'Zeigen Sie den Standort an',
    ADD_TO_FAV_LABEL: 'Zu den Favoriten hinzufügen',
    REMOVE_FROM_FAV_LABEL: 'Von Favoriten entfernen',
    FAV_LABEL: 'Favoriten',
    MAKE_AN_ORDER_LABEL: 'Eine Bestellung aufgeben',
    SEE_PREV_ORDERS_LABEL: 'Zeigen Sie die vorherigen Bestellungen an',
    PREV_ORDERS_LABEL: 'Vorherige Bestellungen',
    DATE_LABEL: 'Datum',
    ESTABLISHMENT_LABEL: 'Einrichtung',
    REPEAT_ORDER_LABEL: 'Bestellung wiederholen',
    UPDATE_PROFILE_LABEL: 'Profil bearbeiten',
    NO_RESULTS_LABEL: 'Keine Ergebnisse',
    CHANGE_FILTERS_LABEL: 'Filter bearbeiten',
    TOWN: 'Stadt',
    PROVINCE: 'Provinz',
    MONTH_NAMES: ['Januar', 'Februar', 'März', 'Abril', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    DAY_NAMES: {
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag'
    }
};

const ca_lang = {
    REGISTER_HEADER: 'Benvingut/da a QUBE Cloud!',
    REGISTER_LABEL: 'Registra\'t',
    YOUR_PROFILE: 'El teu perfil',
    NAME_LABEL: 'Nom',
    STREET_LABEL: 'Carrer',
    ENTRANCE_LABEL: 'Pis',
    FLOOR_LABEL: 'Planta',
    POST_CODE_LABEL: 'Codi postal',
    SET_LOCATION_LABEL: 'Escollir ubicació',
    BEEN_SET_LOCATION_LABEL: 'Ubicació escollida',
    CLEAR_LABEL: 'Netejar',
    PHONE_LABEL: 'Telèfon',
    EMAIL_LABEL: 'E-mail',
    LANGUAGE_LABEL: 'Idioma',
    AGREE_POLICY_LABEL: 'Accepto la política de privacitat',
    POLICY_LABEL: 'La política de privacitat',
    CURRENT_LOCATION_LABEL: 'Ubicació actual',
    PROFILE_LABEL: 'El teu perfil',
    HISTORY_LABEL: 'Historial de comandes',
    EXIT_LABEL: 'Sortir',
    CHOOSE_LANGUAGE_LABEL: 'Selecciona l\'idioma',
    WATCH_LOCATION_LABEL: 'Veure la ubicació',
    ADD_TO_FAV_LABEL: 'Agregar a favorits',
    REMOVE_FROM_FAV_LABEL: 'Llevar de favorits',
    FAV_LABEL: 'Llista de favorits',
    MAKE_AN_ORDER_LABEL: 'Fer una comanda',
    SEE_PREV_ORDERS_LABEL: 'Veure comandes anteriors',
    PREV_ORDERS_LABEL: 'Comandes anteriors',
    DATE_LABEL: 'Data',
    ESTABLISHMENT_LABEL: 'Establiment',
    REPEAT_ORDER_LABEL: 'Repetir la comanda',
    UPDATE_PROFILE_LABEL: 'Editar perfil',
    NO_RESULTS_LABEL: 'No hi ha resultats',
    CHANGE_FILTERS_LABEL: 'Editar filtres',
    TOWN: 'Ciutat',
    PROVINCE: 'Provincia',
    MONTH_NAMES: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'],
    DAY_NAMES: {
        monday: 'Diluns',
        tuesday: 'Dimarts',
        wednesday: 'Dimecres',
        thursday: 'Dijous',
        friday: 'Divendres',
        saturday: 'Dissabte',
        sunday: 'Diumenge'
    }
};

const dicts = {
    'ru-RU': {
        language: 'Русский',
        translation: ru_lang
    },
    'es-ES': {
        language: 'Español',
        translation: es_lang
    },
    'en-EN': {
        language: 'English',
        translation: en_lang
    },
    'de-DE': {
        language: 'Deutsch',
        translation: de_lang
    },
    'ca-CA': {
        language: 'Català',
        translation: ca_lang
    }
};

export default dicts;