import React from 'react';
import { SwipeableDrawer, IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from '../icons';

export default function Menu (props) {
    const [drawer, setDrawer] = React.useState(false);
  
    const handleToggleDrawer = () => {
		setDrawer(!drawer);
    };
    
    return (
		<div>
			<IconButton edge='start' color='inherit' onClick={handleToggleDrawer}>
				<MenuIcon style={{width: 35, height: 35, padding: 5, backgroundColor: 'white', borderRadius: 10}}/>
			</IconButton>
			<SwipeableDrawer
				anchor='right'
				open={drawer}
				onClose={handleToggleDrawer}
				onOpen={handleToggleDrawer}
			>
				{props.children ?? <h1>Menu is empty</h1>}
			</SwipeableDrawer>
		</div>
	);
};