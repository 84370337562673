export default function Body(props) {
    return (
        <div style={{
            paddingTop: 80,
            position: 'realtive',
            height: 'calc(100% - 80px)',
            maxHeight: 'calc(100% - 80px)',
            ...props.style
        }}>
            {props.children ?? <h1>Body is empty</h1>}
        </div>
    );
};