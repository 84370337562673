import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ScrollPagedView from 'react-scroll-paged-view'
import { useSelector } from 'react-redux';
import axios from 'axios';

import dicts from '../constants/translations';
import { SideMenu, Header, Body, Fab, DotStrip, Menu, PageView } from '../components';

import { ReactComponent as LogoBlue } from '../icons/logo-blue.svg';

function FavouritePage(props) {
    const history = useHistory();
    const language = useSelector((state) => state.language.language);
    const token = useSelector((state) => state.token.token);
    const [response, setResponse] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(1);
    const [items, setItems] = React.useState({});
    const [pageView, setPageView] = React.useState(<div>No favourites</div>);

    React.useEffect(() => {
        axios.get('https://api.lakomanda.net/token/', {
            params: {
                token: token,
                language: language
            }
        }).then(res => {
            setResponse(res.data);
            if (res.data.data) {
                if (res.data.data.user_establishments && res.data.data.user_establishments.page) {
                    setPage(res.data.data.user_establishments.page);
                }
            }
        }).catch(console.log);
    }, [token, language]);

    React.useEffect(() => {
        if (response) {
            if (response.data) {
                if (response.data.user_establishments) {
                    if (response.data.user_establishments.is_first_page && !response.data.user_establishments.is_last_page) {
                        axios.get('https://api.lakomanda.net/token/', {
                            params: {
                                token: token,
                                language: language,
                                page: 2
                            }
                        }).then(res => {
                            setResponse(res.data);
                        }).catch(console.log);
                    }
                }
            }
        }
    }, [token, language, response]);

    React.useEffect(() => {
        if (response && response.data && response.data.user_establishments) {
            let bufItems = items;
            bufItems[response.data.user_establishments.page] = response.data.user_establishments.items;
            setItems(bufItems);
            let goToPage = () => { };
            const setPageForce = (p) => {
                setPage(p);
                goToPage(p);
            };
            console.log(items);
            setPageView(
                <>
                    <DotStrip max={pages} curr={page} activeDot={activeDot} setPage={setPageForce} />
                    <ScrollPagedView vertical={false} renderHeader={(e) => { /* goToPage = e.goToPage; */ }} duration={200} initialPage={0} preRenderRange={1} isMovingRender={true} onChange={_onChange} style={{ display: 'none', flexWrap: 'none' }}>
                        {
                            items !== null && typeof items[Object.keys(items)[0]] !== 'undefined' ?
                                Object.keys(items).map((key, i) => {
                                    return <PageView key={'pageview-' + i}>
                                        <Grid container spacing={0}>
                                            {
                                                items[key].map((item, i) => {
                                                    return <Grid key={'item-' + i} item xs={6} style={{ paddingLeft: 5, paddingTop: 10, paddingRight: 5 }}>
                                                        <div onClick={() => { history.push('/establishment/' + item.id + '/?from=fav') }} style={{
                                                            backgroundImage: 'url(' + item.logo + ')',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundSize: 'cover',
                                                            minHeight: '22vh',
                                                            borderRadius: 10
                                                        }} />
                                                    </Grid>;
                                                })
                                            }
                                        </Grid>
                                    </PageView>;
                                })
                                : <div>No Items</div>
                        }
                    </ScrollPagedView>
                </>
            );
        }
    }, [response, items, page, pages, history]);

    React.useEffect(() => {
        if (response && response.data && response.data.user_establishments) {
            let maxPage = Object.keys(items).map(v => parseInt(v)).sort();
            maxPage = maxPage[maxPage.length - 1];
            if (page >= maxPage && !response.data.user_establishments.is_last_page) {
                axios.get('https://api.lakomanda.net/token/', {
                    params: {
                        token: token,
                        page: page + 1,
                        language: language
                    }
                }).then(res => {
                    setResponse(res.data);
                }).catch(console.log);
            }
        }
    }, [response, page, items, token, language])

    const activeDot = React.useRef();
    const executeScroll = (cb) => {
        if (activeDot.current) {
            activeDot.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
        }
        cb();
    };

    React.useEffect(() => {
        if (response && response.data && response.data.user_establishments) {
            setPages(response.data.user_establishments.pages);
        }
    }, [response])

    React.useEffect(() => {
        const timer = setInterval(() => {
            executeScroll(
                () => { clearInterval(timer) }
            )
        }, 700);
        return (() => { clearInterval(timer) });
    }, [page]);

    function _onChange(current, previous) {
        setPage(current + 1);
    };

    return (
        <div>
            <Header color={'white'}>
                <LogoBlue />
                <p>{dicts[language].translation.FAV_LABEL}</p>
                <Menu>
                    <SideMenu />
                </Menu>
            </Header>
            <Fab />
            <Body>
                {pageView}
            </Body>
        </div>
    );
};

// function FavPageWrapper(props){
//     const history = useHistory();
//     const language = useSelector((state) => state.language.language);
//     const token = useSelector((state) => state.token.token);

//     return <FavPage token={token} language={language} history={history} />;
// }

// class FavPage extends React.Component {
//     constructor(props){
//         super(props);
//         this.state = {
//             is_last_page: false,
//             pages:{}
//         };
//     }

//     _requestPages(){
//         axios.get('https://api.lakomanda.net/token/', {
//             params: {
//                 token: this.props.token,
//                 language: this.props.language
//             }
//         })
//         .then(response=>{
//             this.setState(prevState=>{
//                 return {...prevState, is_last_page: response.data.data.user_establishments.is_last_page, pages:{...prevState.pages, [response.data.data.user_establishments.page]: response.data.data.user_establishments.items}};
//             });
//         })
//         .catch(console.log);
//     }

//     componentDidMount(){
//         this._requestPages();
//     }

//     componentDidUpdate(){
//         if (this.state.is_last_page !== true) {
//             console.log('request');
//             //72316m5
//         }
//         console.log(this.state);
//     }

//     render(){
//         return <div></div>;
//     }
// }

export default FavouritePage;